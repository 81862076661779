import React from 'react';
import { connect } from 'react-redux';

import Section from '../CATHSection';
import Heading from '../Headings';
import HulkText from '../HulkText';
import Grid4up from '../Grid-4up';
import Image from '../Image';

import styles from './styles.scss';

const HealthWork = ({ work }) => {
  if (!work) {
    return null;
  }

  const { healthBigText, healthHeading, healthBody, healthProjects } = work;

  const articles = healthProjects.map(
    ({
      id,
      projectName,
      description,
      imageGallery,
      useExternalLink,
      projectLink,
      externalLink
    }) => ({
      id,
      useExternalLink,
      projectLink,
      externalLink,
      image: imageGallery[0].url,
      thumb: imageGallery[0].url,
      eyebrow: projectName,
      title: description,
      slides: imageGallery.slice(1).map(({ url, title }, index) => ({
        id: index,
        content: (
          <Image
            image={url}
            alt={title}
            sizes={[
              { actual: 280, intrinsic: 450 },
              { actual: 448, intrinsic: 768 }
            ]}
          />
        )
      }))
    })
  );

  return (
    <Section className={styles.work} data-name="work" align="left">
      <HulkText classes={styles.hulkText} text={healthBigText} />
      <Heading text={healthHeading} classes={[styles.heading].join(' ')} />
      <div className={styles.body} dangerouslySetInnerHTML={{ __html: healthBody.content }} />
      <div className={styles.section__modules}>
        <Grid4up articles={articles} />
      </div>
    </Section>
  );
};

const mapStateToProps = storeState => ({
  work: storeState.work
});

export default connect(mapStateToProps)(HealthWork);
