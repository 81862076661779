/**
 * @NOTE: SVG's should convert first all strokes to fill.
 * Add icons in alphabetical order.
 */
const data = {
  arrowLeft: {},
  arrowRight: {},
  calendar: {
    viewbox: '0 0 50 45',
    path: 'M31.521 6.25V1.875a1.875 1.875 0 1 1 3.75 0V6.25h12.854c.498 0 .974.197 1.326.549.352.352.549.828.549 1.326v35c0 .498-.197.974-.549 1.326a1.878 1.878 0 0 1-1.326.549H1.875c-.498 0-.974-.197-1.326-.549A1.878 1.878 0 0 1 0 43.125v-35c0-.498.198-.974.549-1.326a1.878 1.878 0 0 1 1.326-.549H15V1.875a1.876 1.876 0 0 1 3.75 0V6.25h12.771zM15 10H3.75v31.25h42.5V10H35.271v3.125a1.876 1.876 0 0 1-3.75 0V10H18.75v3.125a1.876 1.876 0 0 1-3.75 0V10zm-1.25 23.71H10v-3.75h3.75v3.75zm13.086 0h-3.75v-3.75h3.75v3.75zm13.164 0h-3.75v-3.75H40v3.75zM13.75 25H10v-3.75h3.75V25zm13.086 0h-3.75v-3.75h3.75V25zM40 25h-3.75v-3.75H40V25z'
  },
  chevronThickDown: {
    viewbox: '0 0 60 36',
    path: 'M30 36L0 4.933 4.721 0 30 26.178 55.279 0 60 4.933z'
  },
  chevronThickUp: {
    viewbox: '0 0 60 36',
    path: 'M30 0L0 31.067 4.721 36 30 9.822 55.279 36 60 31.067z'
  },
  chevronThinDown: {
    viewbox: '0 0 72 38',
    path: 'M35.4,37.2c-0.1,0-0.3-0.1-0.4-0.1L-0.4,1.7c-0.2-0.2-0.2-0.5,0-0.7s0.5-0.2,0.7,0l35,35l35-35c0.2-0.2,0.5-0.2,0.7,0 s0.2,0.5,0,0.7L35.7,37.1C35.6,37.2,35.5,37.2,35.4,37.2z'
  },
  chevronThinUp: {
    viewbox: '0 0 71.7 36.4',
    path: 'M71.2,36.4c-0.1,0-0.3,0-0.4-0.1l-35-35l-35,35c-0.2,0.2-0.5,0.2-0.7,0s-0.2-0.5,0-0.7L35.5,0.1C35.6,0.1,35.7,0,35.9,0 l0,0c0.1,0,0.3,0.1,0.4,0.1l35.4,35.4c0.2,0.2,0.2,0.5,0,0.7C71.5,36.3,71.3,36.4,71.2,36.4z'
  },
  close: {
    viewbox: '0 0 51 51',
    path: 'M50.5,51c-0.1,0-0.3,0-0.4-0.1L25.5,26.2L0.9,50.9c-0.2,0.2-0.5,0.2-0.7,0s-0.2-0.5,0-0.7l24.6-24.6L0.1,0.9 C0,0.7,0,0.3,0.1,0.1s0.5-0.2,0.7,0l24.6,24.6L50.1,0.1c0.2-0.2,0.5-0.2,0.7,0s0.2,0.5,0,0.7L26.2,25.5l24.6,24.6 c0.2,0.2,0.2,0.5,0,0.7C50.8,51,50.6,51,50.5,51z'
  },
  email: {
    viewbox: '0 0 14 11',
    path: 'M.476 1.675v-.342C.476.873.85.5 1.31.5h11.666c.46 0 .834.373.834.833v.342L7.143 5.842.476 1.675zm6.888 5.012a.417.417 0 0 1-.442 0L.476 2.658v7.009c0 .46.373.833.834.833h11.666c.46 0 .834-.373.834-.833V2.658L7.364 6.687z'
  },
  facebook: {
    viewbox: '0 0 10 20',
    path: 'M10,0L10,0L10,0v4H8C7.3,4,7,4.8,7,5.5V8l0,0h3v4H7v8H3v-8H0V8h3V4c0-2.2,1.8-4,4-4H10z'
  },
  instagram: {
    viewbox: '-576 768 22 22',
    path: 'M-559.1,771.8c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3 C-557.8,772.4-558.4,771.8-559.1,771.8L-559.1,771.8z M-565,782.7c-2,0-3.7-1.6-3.7-3.7c0-2,1.6-3.7,3.7-3.7c2,0,3.7,1.6,3.7,3.7 C-561.3,781-563,782.7-565,782.7L-565,782.7z M-565,773.3c-3.1,0-5.6,2.5-5.6,5.6c0,3.1,2.5,5.6,5.6,5.6s5.6-2.5,5.6-5.6 C-559.4,775.9-561.9,773.3-565,773.3L-565,773.3z M-556,783.4c0,1.1-0.2,1.7-0.4,2c-0.2,0.5-0.4,0.9-0.8,1.3 c-0.4,0.4-0.8,0.6-1.3,0.8c-0.4,0.2-1,0.3-2,0.4c-1.2,0.1-1.5,0.1-4.4,0.1s-3.3,0-4.4-0.1c-1.1,0-1.7-0.2-2-0.4 c-0.5-0.2-0.9-0.4-1.3-0.8c-0.4-0.4-0.6-0.8-0.8-1.3c-0.2-0.4-0.3-1-0.4-2c-0.1-1.2-0.1-1.5-0.1-4.4s0-3.3,0.1-4.4 c0-1.1,0.2-1.7,0.4-2c0.2-0.5,0.4-0.9,0.8-1.3c0.4-0.4,0.8-0.6,1.3-0.8c0.4-0.2,1-0.3,2-0.4c1.2-0.1,1.5-0.1,4.4-0.1s3.3,0,4.4,0.1 c1.1,0,1.7,0.2,2,0.4c0.5,0.2,0.9,0.4,1.3,0.8c0.4,0.4,0.6,0.7,0.8,1.3c0.2,0.4,0.3,1,0.4,2c0.1,1.2,0.1,1.5,0.1,4.4 C-556,781.9-556,782.3-556,783.4L-556,783.4z M-554.6,771.8c-0.3-0.7-0.7-1.3-1.3-1.9c-0.6-0.6-1.2-1-1.9-1.3 c-0.7-0.3-1.5-0.5-2.7-0.5c-1.2-0.1-1.5-0.1-4.5-0.1s-3.4,0-4.5,0.1c-1.2,0.1-2,0.2-2.7,0.5c-0.7,0.3-1.3,0.7-1.9,1.3 c-0.6,0.6-1,1.2-1.3,1.9c-0.3,0.7-0.5,1.5-0.5,2.7c-0.1,1.2-0.1,1.5-0.1,4.5s0,3.4,0.1,4.5c0.1,1.2,0.2,2,0.5,2.7 c0.3,0.7,0.7,1.3,1.3,1.9c0.6,0.6,1.2,1,1.9,1.3c0.7,0.3,1.5,0.5,2.7,0.5c1.2,0.1,1.5,0.1,4.5,0.1s3.4,0,4.5-0.1 c1.2-0.1,2-0.2,2.7-0.5c0.7-0.3,1.3-0.7,1.9-1.3c0.6-0.6,1-1.2,1.3-1.9c0.3-0.7,0.5-1.5,0.5-2.7c0.1-1.2,0.1-1.5,0.1-4.5 s0-3.4-0.1-4.5C-554.1,773.3-554.3,772.5-554.6,771.8L-554.6,771.8z'
  },
  linkedin: {
    viewbox: '0 0 18 18',
    path: 'M18,18h-4v-6.8c0-1.1-1.2-1.9-2.2-1.9S10,10.2,10,11.2V18H6V6h4v2c0.7-1.1,2.4-1.8,3.5-1.8c2.5,0,4.5,2,4.5,4.5V18 M4,18H0 V6h4V18 M2,0c1.1,0,2,0.9,2,2S3.1,4,2,4S0,3.1,0,2S0.9,0,2,0z'
  },
  location: {
    viewbox: '0 0 14 20',
    path: 'M7,0C3.1,0,0,3.1,0,7c0,5.2,7,13,7,13s7-7.8,7-13C14,3.1,10.9,0,7,0z M7,9.5C5.6,9.5,4.5,8.4,4.5,7S5.6,4.5,7,4.5 S9.5,5.6,9.5,7S8.4,9.5,7,9.5z'
  },
  menu: {
    viewbox: '0 0 50 29',
    path: 'M.098 0H50v1.899H.098zM.098 13.29H50v1.899H.098zM.098 26.58H50v1.899H.098z'
  },
  mute: {
    viewbox: '0 0 50 41',
    path: 'M10.072 11.402L23.235.39c1.048-.918 2.643-.102 2.693 1.274v37.064c0 1.428-1.596 2.244-2.693 1.326L10.072 29.042l-7.38-.204C1.148 28.736 0 26.9 0 25.32V15.124c0-1.53 0-3.518 2.693-3.518l7.379-.204zM44.83 19.57l4.944 4.945a.839.839 0 0 1 0 1.146l-2.894 2.894a.838.838 0 0 1-1.145 0L40.79 23.61l-4.944 4.945a.84.84 0 0 1-1.147 0l-2.894-2.894a.839.839 0 0 1 0-1.146l4.945-4.945-4.945-4.944a.839.839 0 0 1 0-1.146l2.894-2.894a.84.84 0 0 1 1.147 0l4.944 4.945 4.945-4.945a.838.838 0 0 1 1.145 0l2.894 2.894a.839.839 0 0 1 0 1.146L44.83 19.57z'
  },
  pause: {
    viewbox: '0 0 50 66',
    path: 'M0 0h11.538v65.385H0zM38.462 0H50v65.385H38.462z'
  },
  play: {
    viewbox: '0 0 50 54',
    path: 'M0 0l50 25.51L0 53.571z'
  },
  search: {
    viewbox: '0 0 50 50',
    path: 'M50 46.444L46.444 50 33.197 36.753c-3.713 2.895-7.93 4.342-12.65 4.342-2.727 0-5.344-.52-7.85-1.558a20.68 20.68 0 0 1-6.687-4.452 20.327 20.327 0 0 1-4.452-6.655A20.396 20.396 0 0 1 0 20.548c0-2.749.52-5.376 1.558-7.883A20.327 20.327 0 0 1 6.01 6.01a20.68 20.68 0 0 1 6.687-4.452A20.318 20.318 0 0 1 20.547 0c2.749 0 5.376.52 7.883 1.558a20.327 20.327 0 0 1 6.655 4.452 20.094 20.094 0 0 1 4.484 6.655 20.396 20.396 0 0 1 1.557 7.883c0 4.699-1.457 8.915-4.373 12.649L50 46.444zM9.534 31.53c3.042 3.042 6.713 4.563 11.014 4.563 4.3 0 7.971-1.521 11.013-4.563 3.02-3.02 4.53-6.681 4.53-10.981 0-4.28-1.51-7.951-4.53-11.014-3.063-3.02-6.734-4.53-11.013-4.53-4.3 0-7.961 1.51-10.982 4.53-3.042 3.042-4.563 6.713-4.563 11.014 0 4.3 1.51 7.96 4.531 10.981z'
  },
  twitter: {
    viewbox: '0 0 1200 1227',
    path: 'M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z'
  },
  volume: {
    viewbox: '0 0 50 43',
    path: 'M10.632 12.035L24.526.411c1.106-.968 2.79-.107 2.842 1.346V40.88c0 1.507-1.684 2.368-2.842 1.4L10.632 30.654l-7.79-.215C1.211 30.332 0 28.395 0 26.727V15.964c0-1.615 0-3.713 2.842-3.713l7.79-.216zm27-3.66c2.842 3.553 4.473 7.965 4.473 12.862 0 4.898-1.684 9.418-4.473 12.97-.421.538-1.106.592-1.58.161l-1.894-1.722c-.421-.377-.474-1.076-.105-1.507a15.975 15.975 0 0 0 3.368-9.848c0-3.713-1.263-7.157-3.368-9.848-.369-.484-.316-1.13.105-1.507l1.895-1.722c.473-.43 1.21-.323 1.579.162zm5.789-5.488C47.526 7.892 50 14.187 50 21.237c0 7.05-2.474 13.454-6.579 18.459-.368.484-1.105.538-1.526.108L40 38.028c-.421-.377-.474-1.023-.105-1.507 3.368-4.144 5.368-9.471 5.368-15.23 0-5.758-2-11.085-5.368-15.23-.369-.43-.316-1.13.105-1.506l1.895-1.776c.42-.376 1.158-.376 1.526.108z'
  }
};

export default data;
