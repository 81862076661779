import React, { useMemo } from 'react';
import { buildURL } from 'react-imgix';

const defaultImgixParams = { auto: 'compress,format', dpr: 1 };

/**
 * Image Component
 *
 * @class      Image (name)
 * @param      {string}   image          The base url for the imgix image
 * @param      {string}   alt            Image alt
 * @param      {string}   classes        Classname to be added into the img
 * @param      {Object}   sizes          Defines image size
 * @return     {Object}   JSX render
 */
const Image = ({
  image,
  alt,
  ariaHidden,
  classes = '',
  sizes = [],
  imgixParams: extraImgixParams = {},
  onLoad
}) => {
  if (!image) return null;

  const imgixParams = { ...defaultImgixParams, ...extraImgixParams };

  const srcset = useMemo(
    () =>
      sizes.map(
        size => `${buildURL(image, { ...imgixParams, w: size.intrinsic })} ${size.actual}w`
      ),
    [image, JSON.stringify(extraImgixParams)]
  );

  const lastSize = sizes[sizes.length - 1];

  const maxSize = Math.max(...sizes.map(size => size.intrinsic));
  const src = useMemo(
    () => buildURL(image, lastSize ? { ...imgixParams, w: maxSize } : { ...imgixParams }),
    [image, JSON.stringify(extraImgixParams)]
  );

  return (
    <img
      className={`${classes} lzy lazyload `}
      aria-hidden={ariaHidden}
      alt={alt}
      data-src={src}
      data-srcset={srcset.join(', ')}
      data-sizes="auto"
      data-optimumx="1.1"
      onLoad={onLoad}
    />
  );
};
export default Image;
