import React from 'react';
import { hydrate } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { ApolloProvider } from 'react-apollo';

import { getPreviewVars } from 'helpers/preview';
import { apiPath, apiToken } from 'config/constants';
import store from './redux/store';
import introspectionQueryResultData from '../../fragmentTypes.json';

import App from './app';

// Create a new fragment matcher
const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData
});

const client = new ApolloClient({
  link: createHttpLink({
    uri: apiPath + getPreviewVars(),
    credentials: 'same-origin',
    headers: {
      authorization: apiToken ? `bearer ${apiToken}` : ''
    }
  }),
  cache: new InMemoryCache({ fragmentMatcher }).restore(window.__APOLLO_STATE__),
  ssrForceFetchDelay: 100
});

hydrate(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </ApolloProvider>,
  document.getElementById('content')
);

if (module.hot) {
  module.hot.accept('./app', () => {
    hydrate(
      <ApolloProvider client={client}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ApolloProvider>,
      document.getElementById('content')
    );
  });
}
