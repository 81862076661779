import React from 'react';
import { connect } from 'react-redux';
import { gtmPush } from '../../../helpers/dataLayer';
import styles from './styles.scss';

const HealthCompanyNav = ({ navigation }) => {
  const navigationItems =
    navigation && navigation.healthNavigationItems ? navigation.healthNavigationItems : [];

  return (
    <div className={styles.companyNav}>
      {navigationItems.length ? (
        <div className={styles.companyNav__wrapper}>
          {navigationItems.map(({ label, subLabel, navigationUrl }) => (
            <div className={styles.companyNav__item}>
              <a
                href={navigationUrl}
                className={styles.companyNav__link}
                target="_blank"
                rel="noopener noreferrer"
                dangerouslySetInnerHTML={{ __html: label.content }}
                onClick={() => {
                  gtmPush({ event: 'navigationItem' });
                }}
              />
              <div dangerouslySetInnerHTML={{ __html: subLabel.content }} />
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

const mapStateToProps = state => ({
  navigation: state.navigation
});

export default connect(mapStateToProps)(HealthCompanyNav);
