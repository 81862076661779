import React from 'react';
import { Query } from 'react-apollo';
import { connect } from 'react-redux';

import healthQuery from '../../../config/query';
import { extractHealthField } from '../../../helpers/healthFields';

import {
  SET_NAVIGATION,
  SET_HERO,
  SET_CONTENTS,
  SET_WORK,
  SET_CONTACT,
  SET_FOOTER_NAVIGATION,
  SET_FOOTER_LEGAL,
  SET_RECENT_NEWS
} from '../../../redux/actionTypes';

/**
 * TO DO:
 * On react upgrade, consider using context to manage query data
 */

const mapDispatchToProps = dispatch => ({
  setNavigation: data => dispatch({ type: SET_NAVIGATION, data }),
  setHero: data => dispatch({ type: SET_HERO, data }),
  setContents: data => dispatch({ type: SET_CONTENTS, data }),
  setWork: data => dispatch({ type: SET_WORK, data }),
  setContact: data => dispatch({ type: SET_CONTACT, data }),
  setFooterNavigation: data => dispatch({ type: SET_FOOTER_NAVIGATION, data }),
  setFooterLegal: data => dispatch({ type: SET_FOOTER_LEGAL, data }),
  setRecentNews: data => dispatch({ type: SET_RECENT_NEWS, data })
});

const HealthProvider = ({ children, ...props }) => (
  <Query query={healthQuery}>
    {({ data }) => {
      const {
        setNavigation,
        setHero,
        setContents,
        setWork,
        setContact,
        setFooterNavigation,
        setFooterLegal,
        setRecentNews
      } = props;

      if (data && data.entry) {
        const {
          entry: { healthRecentNews }
        } = data;

        setRecentNews(healthRecentNews);
      }

      const [navigation] = extractHealthField('navigation', data);
      const [hero, ...contents] = extractHealthField('contents', data);
      const [work] = extractHealthField('work', data);
      const [contact] = extractHealthField('contact', data);
      const [footerNavigation] = extractHealthField('footerNavigation', data);
      const [footerLegal] = extractHealthField('footerLegal', data);

      setNavigation(navigation);
      setContact(contact);
      setHero(hero);
      setContents(contents);
      setWork(work);
      setFooterNavigation(footerNavigation);
      setFooterLegal(footerLegal);

      return children;
    }}
  </Query>
);

export default connect(null, mapDispatchToProps)(HealthProvider);
