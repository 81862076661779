import React from 'react';

import Image from '../Image';

import styles from './styles.scss';

const BackgroundImage = props => {
  const { bgRef, images, alt, className = '', sizes } = props;

  return (
    <div ref={bgRef} className={`background ${styles.background} ${className}`} aria-hidden="true">
      <Image image={images} alt={alt} sizes={sizes} />
    </div>
  );
};

export default BackgroundImage;
