import URL from 'url';
import queryString from 'querystring';
import canUseDOM from './canUseDOM';

export const getPreviewVars = url => {
  let apiQueryVars = '';
  let _url = url;

  if (typeof url === 'undefined') {
    if (canUseDOM) {
      _url = window.location.href;
    }
  }

  if (_url) {
    const currentUrl = URL.parse(_url);
    const queryVars = queryString.parse(currentUrl.query);
    let queryCraftPreview = queryVars['x-craft-preview'] ? queryVars['x-craft-preview'] : false;
    if (!queryCraftPreview) {
      queryCraftPreview = queryVars['x-craft-live-preview']
        ? queryVars['x-craft-live-preview']
        : false;
    }
    const queryCraftToken = queryVars.token ? queryVars.token : false;
    if (queryCraftPreview && queryCraftToken) {
      apiQueryVars = `?${queryString.stringify({
        'x-craft-preview': queryCraftPreview,
        'x-craft-live-preview': queryCraftPreview,
        token: queryCraftToken
      })}`;
    }
  }

  return apiQueryVars;
};

export default null;
