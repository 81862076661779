import React from 'react';

import classNames from 'classnames/bind';
import styles from './styles.scss';

import Section from '../CATHSection';
import Heading from '../Headings';
import HulkText from '../HulkText';

const HealthSection = ({
  name,
  bgImage,
  bgSpeed,
  bgAlt,
  hulkText,
  headingText,
  bodyText,
  align = 'left',
  cta,
  type
}) => (
  <Section
    data-name={name}
    className={classNames(
      styles.section,
      styles[`section--${align}-align`],
      styles[`section--type-${type}`]
    )}
    bgImage={bgImage}
    bgClassName={styles.bgImage}
    bgAlt={bgAlt}
    bgSpeed={bgSpeed}>
    <div className={styles.bigText}>
      <HulkText text={hulkText} classes={styles.hulkText} />
    </div>
    <Heading text={headingText} classes={styles.heading} />
    <div className={styles.body}>
      <div className={styles.bodyText} dangerouslySetInnerHTML={{ __html: bodyText }} />
      {cta && (
        <a className={styles.cta} href={cta.url} target="_blank" rel="noopener noreferrer">
          {cta.text}
        </a>
      )}
    </div>
  </Section>
);

export default HealthSection;
