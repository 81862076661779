import React, { useState, useCallback } from 'react';

import Image from '../Image';
import styles from './styles.scss';
import Modal, { MODAL_FADE_TO } from '../Modal';
import Carousel from '../Carousel';

function getCardImage({ image, alt, projectLink, useExternalLink }) {
  const cardContent = (
    <Image
      image={image}
      alt={alt}
      sizes={[{ actual: 448, intrinsic: 768 }]}
      classes={styles['card__image-thumb']}
    />
  );

  if (useExternalLink) {
    return (
      <a
        href={projectLink}
        className={styles.card__image}
        target="_blank"
        rel="noopener noreferer noreferrer">
        {cardContent}
      </a>
    );
  }
  return <div className={styles.card__image}>{cardContent}</div>;
}

function getEyebrow({ useExternalLink, externalLink, text }) {
  if (useExternalLink) {
    return (
      <a
        href={externalLink}
        className={styles.card__eyebrow}
        target="_blank"
        rel="noopener noreferer noreferrer">
        {text}
      </a>
    );
  }
  return <div className={styles.card__eyebrow}>{text}</div>;
}

function getTitle({ title, useExternalLink, projectLink }) {
  const titleContent = (
    <>
      <span dangerouslySetInnerHTML={{ __html: title }} />
      <span>.</span>
    </>
  );

  if (useExternalLink) {
    return (
      <a
        href={projectLink}
        className={styles.card__title}
        target="_blank"
        rel="noopener noreferer noreferrer">
        {titleContent}
      </a>
    );
  }
  return <div className={styles.card__title}>{titleContent}</div>;
}

const CardArticle = props => {
  const {
    article: { thumb, eyebrow, title, useExternalLink, projectLink, externalLink, slides }
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  const handleClose = useCallback(() => {
    setTimeout(() => {
      setIsOpen(false);
    }, MODAL_FADE_TO);
  }, []);

  const handleModal = useCallback(e => {
    e.preventDefault();
    setIsOpen(true);
  }, []);

  return (
    <>
      <div className={styles['card--article']}>
        <div className={styles.card__wrapper}>
          {getCardImage({ image: thumb, alt: title, projectLink, useExternalLink })}
          <div className={styles.card__body}>
            {getEyebrow({ useExternalLink, externalLink, text: eyebrow })}
            {getTitle({ title, useExternalLink, projectLink })}
          </div>
        </div>
        {!useExternalLink && (
          <button type="button" className={styles.card__link} onClick={handleModal} />
        )}
      </div>
      {isOpen && (
        <Modal isOpen label={`${title} Modal`} onClose={handleClose}>
          <Carousel slides={slides} fullscreen modal />
        </Modal>
      )}
    </>
  );
};

export default CardArticle;
