import React, { useEffect, useRef } from 'react';
import canUseDOM from 'helpers/canUseDOM';
import ResizeObserver from 'resize-observer-polyfill';

const Parallax = props => {
  const { children, speed = 0.3, offsetRatio = 0 } = props;

  const parentRef = useRef(null);
  const elementRef = useRef(null);

  const viewportHeight = useRef(canUseDOM ? window.innerHeight : 0);
  const elementHeight = useRef(0);
  const elementCenter = useRef(0);
  const viewableMin = useRef(0);
  const viewableMax = useRef(0);

  const parallaxing = useRef(false);

  // componentDidMount
  useEffect(() => {
    // Ideally, this should only be called during resize, or initial mount.
    // Should not be called during scroll!
    function computeMetrics() {
      // Layout triggering props
      const _viewportHeight = window.innerHeight;
      const scrollY = window.pageYOffset;
      const parentBox = parentRef.current.getBoundingClientRect();
      const parentHeight = parentRef.current.offsetHeight;
      const _elementHeight = elementRef.current.offsetHeight;
      const elementTopFromParent = elementRef.current.offsetTop;

      // Non-layout triggering computations
      const parentTop = scrollY + parentBox.top;
      const elementTop = parentTop + elementTopFromParent;
      // The "reset" reference for scroll distance.
      const _elementCenter = elementTop + _elementHeight / 2;

      // Viewport viewable region
      const _viewableMin = parentTop - _viewportHeight * 1.5;
      const _viewableMax = parentTop + parentHeight + _viewportHeight * 0.5;

      viewportHeight.current = _viewportHeight;
      elementHeight.current = _elementHeight;
      elementCenter.current = _elementCenter;
      viewableMin.current = _viewableMin;
      viewableMax.current = _viewableMax;

      applyParallax();
    }

    // There should be no layout-triggering stuff here,
    // Just math computations and non-layout style updates.
    function applyParallax() {
      const currentScroll = window.pageYOffset;
      const scrollY = currentScroll <= 0 ? 0 : currentScroll;
      const scrollViewportCenter = scrollY + viewportHeight.current / 2;

      // Update transform only when element is within the viewable region (viewport +- 150%)
      if (scrollY >= viewableMin.current && scrollY <= viewableMax.current) {
        // How far the element is from it's "viewport-center" coordinates
        const centerDeviation = elementCenter.current - scrollViewportCenter;
        const translateY = centerDeviation * speed + elementHeight.current * offsetRatio;

        // Apply styles.
        elementRef.current.style.transform = `translateY(${translateY}px)`;
      }
    }

    function resizeHandler() {
      window.requestAnimationFrame(computeMetrics);
    }

    function scrollHandler() {
      if (parallaxing.current) return;
      parallaxing.current = true;
      window.requestAnimationFrame(() => {
        applyParallax();
        parallaxing.current = false;
      });
    }

    const resizeObserver = new ResizeObserver(resizeHandler);

    elementRef.current = children.props.bgRef.current;
    parentRef.current = elementRef.current.parentElement;

    // Ensure our element is transform-efficient
    elementRef.current.style.willChange = 'transform';
    // elementRef.current.style.transition = 'transform .5s cubic-bezier(.28,.62,.47,1.01)';

    computeMetrics();

    window.addEventListener('scroll', scrollHandler);

    resizeObserver.observe(document.body);
    resizeObserver.observe(parentRef.current);
    resizeObserver.observe(elementRef.current);

    // Cleanup
    return () => {
      resizeObserver.disconnect();
      window.removeEventListener('scroll', scrollHandler);
    };
  }, []);

  return <>{children}</>;
};

export default Parallax;
