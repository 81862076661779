import React from 'react';

import CardArticle from '../CardArticle';

import styles from './styles.scss';

const Grid4up = ({ articles = [] }) => (
  <ul className={styles['grid-4up']}>
    {articles.length !== 0
      ? articles.map((article, i) => (
          <li key={i}>
            <CardArticle article={article} />
          </li>
        ))
      : 'Noa'}
  </ul>
);

export default Grid4up;
