import React from 'react';
import Meta from '../Meta';
import styles from './styles.scss';

const NotFound = () => (
  <>
    <Meta active={true} />
    <section className={styles['error-container']}>
      <video className={styles['error-video']} playsInline autoPlay muted loop>
        <source src="/videos/404.mp4" type="video/mp4" />
        <source src="/videos/404.webm" type="video/webm" />
      </video>

      <div className={styles['error-details__inner']}>
        <aside className={styles['error-details']}>
          <p>
            <strong>You may have mistyped</strong> the address or the page may have moved.
          </p>
          <a href="/">Back to Homepage</a>
        </aside>
      </div>
    </section>
  </>
);

export default NotFound;
