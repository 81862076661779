import canUseDOM from './canUseDOM';

const { navigator } = canUseDOM ? window : {};

const deviceInfo = {
  isTouch: canUseDOM ? 'ontouchstart' in window : false,
  isAndroid: canUseDOM ? navigator.userAgent.match(/Android/i) : false,
  isIOS: canUseDOM
    ? Boolean(
        navigator.userAgent.match(/iPhone/i) ||
          navigator.userAgent.match(/iPad/i) ||
          navigator.userAgent.match(/iPod/i)
      )
    : false,
  isIE11: canUseDOM ? Boolean(navigator.userAgent.match(/Trident\/7\./)) : false,
  isSafari: canUseDOM ? Boolean(navigator.userAgent.match(/Version\/[\d.]+.*Safari/)) : false,
  isMobile: canUseDOM
    ? /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    : false,
  isFirefox: canUseDOM ? Boolean(navigator.userAgent.match(/Firefox/i)) : false,
  isEdge: canUseDOM ? Boolean(navigator.userAgent.match(/Edg/i)) : false
};

export default deviceInfo;
