import React from 'react';

import styles from './styles.scss';

const InfoCard = ({ number, headingText, body, classes }) => (
  <div className={[styles.card, classes].join(' ')} key={number} classes={classes}>
    <div className={styles.number}>{number}</div>
    <h3 className={styles.heading}>{headingText}</h3>
    <div className={styles.body} dangerouslySetInnerHTML={{ __html: body }} />
  </div>
);

export default InfoCard;
