import React from 'react';

import styles from './styles.scss';

const LocationCard = ({ classes, heading, subheading, address, phone, division }) => (
  <div className={[styles.card, classes].join(' ')}>
    <h3 className={styles.heading}>{heading}</h3>
    <h4 className={styles.subheading} dangerouslySetInnerHTML={{ __html: subheading }} />
    <section className={styles.info}>
      <div className={styles.address}>
        <span className={styles.division}>{division}</span>
        <div dangerouslySetInnerHTML={{ __html: address }} />
      </div>
      <a href={`tel:${phone}`} className={styles.phone}>
        {phone}
      </a>
    </section>
  </div>
);

export default LocationCard;
