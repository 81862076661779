import React from 'react';

import { wrapWords } from 'helpers/text';
import styles from './styles.scss';

const ContactCard = ({
  heading,
  link,
  ctaLinkName,
  type = 'email',
  classes,
  headingClasses,
  linkClasses
}) => {
  let contactLink = '';

  switch (type) {
    case 'email':
      contactLink = `mailto:${link}`;
      break;
    case 'phone':
    case 'fax':
    case 'tel':
      contactLink = `tel:${link}`;
      break;
    default:
  }

  return (
    <div className={[styles.card, classes].join(' ')}>
      <div
        className={[styles.heading, headingClasses].join(' ')}
        dangerouslySetInnerHTML={{ __html: wrapWords(heading) }}
      />
      <a className={[styles.link, linkClasses].join(' ')} href={contactLink}>
        {ctaLinkName}
      </a>
    </div>
  );
};

export default ContactCard;
