import React, { useRef } from 'react';

import BackgroundImage from '../BackgroundImage';
import Parallax from '../HealthParallax';
import styles from './styles.scss';

const CATHSection = _props => {
  const {
    className,
    bgImage,
    bgClassName,
    bgAlt,
    bgSpeed,
    children,
    align = 'left',
    ...props
  } = _props;

  const bgRef = useRef();

  return (
    <section
      className={[styles.section, className || '', styles[`section--${align}Align`]].join(' ')}
      {...props}>
      {bgImage && (
        <Parallax speed={bgSpeed}>
          <BackgroundImage
            bgRef={bgRef}
            images={bgImage}
            alt={bgAlt}
            className={[bgClassName, styles.bgImage].join(' ')}
            sizes={[{ actual: 576, intrinsic: 768 }]}
          />
        </Parallax>
      )}
      <div className={styles.section__content}>{children}</div>
    </section>
  );
};

export default CATHSection;
