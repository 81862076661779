import React, { useState, useEffect } from 'react';
import ReactModal from 'react-modal';

import styles from './styles.scss';

// MODAL_FADE_TO needs to match css transition speed defined in styles.
export const MODAL_FADE_TO = 500;

const Modal = props => {
  const { isOpen, onClose, label, children } = props;
  const [showModal, setShowModal] = useState(false);

  // componentDidMount
  useEffect(() => {
    function handleEscKey(event) {
      const key = event.key || event.keyCode;
      if (key === 'Escape' || key === 'Esc' || key === 27) {
        handleCloseModal();
      }
    }

    if (isOpen) {
      setShowModal(true);
    }

    ReactModal.setAppElement('#content');
    window.addEventListener('keyup', handleEscKey, false);

    // Cleanup
    return () => {
      window.removeEventListener('keyup', handleEscKey, false);
    };
  }, []);

  function handleCloseModal() {
    setShowModal(false);
    onClose();
  }

  function removeTabIndex() {
    const modalDiv = document.querySelector('.ReactModal__Content');
    modalDiv.removeAttribute('tabindex');
  }

  return (
    <ReactModal
      isOpen={showModal}
      contentLabel={label}
      className={styles.modal}
      overlayClassName={styles['modal-overlay']}
      closeTimeoutMS={MODAL_FADE_TO}
      onAfterOpen={removeTabIndex}
      portalClassName={styles.ReactModalPortal}
      bodyOpenClassName={styles['ReactModal__Body--open']}>
      <div className={styles.modal__body}>
        <div className={styles.modal__wrapper}>{children}</div>
      </div>

      <button
        type="button"
        className={styles.modal__close}
        onClick={handleCloseModal}
        aria-label="Close Modal">
        <span />
      </button>
    </ReactModal>
  );
};

export default Modal;
