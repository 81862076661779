import React from 'react';

import HealthPageRenderer from '../HealthPageRenderer';
import HealthIntro from '../../components/HealthComps/HealthIntro';
import HealthRecentNews from '../../components/HealthComps/HealthRecentNews';
import HealthBlocks from '../../components/HealthComps/HealthBlocks';
import HealthContact from '../../components/HealthComps/HealthContact';
import HealthCompanyNav from '../../components/HealthComps/HealthCompanyNav';
import HealthWork from '../../components/HealthComps/HealthWork';

import styles from './styles.scss';

const Health = () => (
  <HealthPageRenderer classes={styles.health} withGrid={true}>
    <HealthIntro />
    <HealthRecentNews />
    <HealthBlocks />
    <HealthWork />
    <HealthCompanyNav />
    <HealthContact />
  </HealthPageRenderer>
);

export default Health;
