import { useEffect } from 'react';
import EventHandler from 'helpers/EventHandler';
import { EVENT } from 'config/constants';

const GridGuide = () => {
  // componentDidMount
  useEffect(() => {
    const showGrid = false;

    function toggleGridGuide(_, e) {
      if (e.ctrlKey && e.keyCode === 71) {
        showGrid = !showGrid;
        document.body.classList.toggle('showGrid', showGrid);
      }
    }

    const __EVENT_KEYDOWN = EventHandler.subscribe(EVENT.KEYDOWN, toggleGridGuide);

    // Cleanup
    return () => EventHandler.unsubscribe(__EVENT_KEYDOWN);
  }, []);

  return null;
};

export default GridGuide;
