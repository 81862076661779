import React, { useState, useEffect } from 'react';

import { gtmPush } from '../../helpers/dataLayer';
import HealthHeader from '../../components/HealthComps/HealthHeader';
import HealthFooter from '../../components/HealthComps/HealthFooter';
import GridGuide from '../../components/GridGuide';

import styles from './styles.scss';

const Health = props => {
  const { classes, children } = props;

  const [loaded, setLoaded] = useState(false);

  // componentDidMount
  useEffect(() => {
    setLoaded(true);
    gtmPush({
      event: 'gtm.messagePageView',
      contentType: 'C&T Health'
    });
  }, []);

  return (
    <div
      className={`${styles.healthPage} ${classes}`}
      style={{ opacity: loaded ? false : '0' }}
      data-loaded={loaded}>
      <a href="#main-content" className={styles.skipToMain}>
        Skip to content
      </a>
      <HealthHeader />
      <div className={styles.healthPage__main} id="main-content">
        {children}
      </div>
      <HealthFooter />
      <GridGuide />
    </div>
  );
};

export default Health;
