// Health Field Types
const healthFieldTypes = {
  navigation: 'HealthFieldsHealthNavigationBlock',
  // hero field should be the first content block
  contents: 'HealthFieldsHealthContentBlock',
  work: 'HealthFieldsHealthWorkBlock',
  contact: 'HealthFieldsHealthContact',
  footerNavigation: 'HealthFieldsHealthFooterNavigationBlock',
  footerLegal: 'HealthFieldsHealthFooterLegalBlock'
};

/**
 * Extracts a desired health field data from the raw graphql query data
 * @param  {String} fieldHandle   - fieldHandle of the field to extract
 * @param  {Object}  rawQueryData - The raw GraphQL query data
 * @return {Object}               - the field data, otherwise NULL
 */
export const extractHealthField = (fieldHandle, rawQueryData = {}) => {
  // Ensure we are receiving the right query data shape
  if ('entry' in rawQueryData && 'healthFields' in rawQueryData.entry) {
    const {
      entry: { healthFields }
    } = rawQueryData;

    if (fieldHandle in healthFieldTypes) {
      const fieldType = healthFieldTypes[fieldHandle];
      return healthFields.filter(({ __typename }) => __typename === fieldType);
    }
  }

  return [];
};
