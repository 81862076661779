import React from 'react';
import svgs from './data';

import styles from './styles.scss';

const getViewBox = name => svgs[name].viewbox || '';
const getPath = name => svgs[name].path || '';

const Icon = ({ name, color, size, classes, onClick }) => {
  const iconSize = typeof size !== 'undefined' ? `${styles[`icon--${size}`]}` : '';
  const iconColor = typeof color !== 'undefined' ? `${styles[`icon--${color}`]}` : '';
  const iconClass = classes instanceof Array ? classes.join(' ') : classes;

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <span
      className={`${iconClass || ''} ${styles.icon} ${iconSize} ${iconColor}`}
      onClick={onClick}>
      <svg
        aria-hidden="true"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox={getViewBox(name)}
        xmlnsXlink="http://www.w3.org/1999/xlink">
        <path d={getPath(name)} />
      </svg>
    </span>
  );
};

export default Icon;
