import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { get } from 'helpers/utilities';
import Image from '../Image';
import { gtmPush } from '../../../helpers/dataLayer';

import styles from './styles.scss';

const NewsLink = ({ news, children, className }) => {
  const newsURL =
    get(news, 'linkType') === 'internalLink'
      ? get(news, 'internalLink[0].url', '').replace('api.', '')
      : get(news, 'externalLink');

  return (
    <a
      href={newsURL}
      className={className}
      onClick={() => {
        gtmPush({
          event: 'recentNews',
          entrySlug: newsURL
        });
      }}>
      {children}
    </a>
  );
};

const HealthRecentNews = ({ recentNews = [] }) => {
  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setFadeIn(true), 1000);
    return () => {
      clearTimeout(timeout);
    };
  });

  return (
    <div className={`${styles.recentNews} ${fadeIn && styles.recentNewsFadeIn}`}>
      <div className={styles.recentNews__wrapper}>
        {recentNews.map((news, index) => (
          <NewsLink news={news} className={styles.recentNews__block} key={`news-item-${index}`}>
            <div className={styles.recentNews__blockLabel}>{get(news, 'eyebrow')}</div>

            <div className={styles.recentNews__content}>
              <div>
                <Image
                  image={get(news, 'image[0].url')}
                  alt={get(news, 'image[0].title')}
                  imgixParams={{ q: 100 }}
                  sizes={[{ actual: 50, intrinsic: 150 }]}
                  ariaHidden="true"
                />
              </div>
              <p>{get(news, 'description')}</p>
            </div>
          </NewsLink>
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = storeState => ({
  recentNews: storeState.recentNews
});

export default connect(mapStateToProps)(HealthRecentNews);
