import React from 'react';
import { connect } from 'react-redux';

import HealthSection from '../HealthSection';
import HealthInfo from '../HealthInfo';

function renderInfoCards(infoCards, bgImage) {
  const items = infoCards.map(({ infoCardTitle, infoCardDescription }) => ({
    title: infoCardTitle,
    body: infoCardDescription.content
  }));

  return <HealthInfo items={items} bgImage={bgImage} />;
}

const SectionBlock = ({
  healthAlignment,
  healthBigText,
  healthHeading,
  healthBody,
  healthCta,
  healthInfoCards,
  healthBackgroundImage
}) => {
  const sectionHandle = healthBigText.toLowerCase();
  const [bgImage] = healthBackgroundImage;
  const cta = healthCta[0] ? { text: healthCta[0].label, url: healthCta[0].ctaLink } : null;

  return (
    <>
      <HealthSection
        name={sectionHandle}
        bgImage={bgImage ? bgImage.url : null}
        bgAlt={bgImage ? bgImage.title : ''}
        hulkText={healthBigText}
        headingText={healthHeading}
        bodyText={healthBody.content}
        align={healthAlignment}
        cta={cta}
        data-name={sectionHandle}
        type={sectionHandle}
      />
      {healthInfoCards.length > 0 && renderInfoCards(healthInfoCards, bgImage)}
    </>
  );
};

const HealthBlocks = ({ contents = [] }) =>
  contents.map((content, index) => <SectionBlock key={index} {...content} />);

const mapStateToProps = storeState => ({
  contents: storeState.contents
});

export default connect(mapStateToProps)(HealthBlocks);
