import deviceInfo from './deviceInfo';
import canUseDOM from './canUseDOM';

/* eslint-disable */

// easeInOutQuad
// https://github.com/danro/jquery-easing/blob/master/jquery.easing.js
// t: current time, b: begInnIng value, c: change In value, d: duration
const getScrollAmount = ({ t, b, c, d }) => {
  if ((t /= d / 2) < 1) return (c / 2) * t * t + b;
  return (-c / 2) * (--t * (t - 2) - 1) + b;
};

const animateScrollTo = targetScroll => {
  const b = window.pageYOffset;
  const c = targetScroll - b;
  const d = c > 5000 ? 1 : c / 5000; // 1s maximum at 5000px,
  const start = Date.now();

  console.log({ b, c, d, targetScroll });

  const step = () => {
    let t = (Date.now() - start) / 1000;
    t = t >= d ? d : t;

    const scrollAmount = getScrollAmount({ t, b, c, d });
    window.scrollTo(0, scrollAmount);

    if (t < d) {
      window.requestAnimationFrame(step);
    }
  };

  step();
};

export const scrollTo = targetScroll => {
  if (!canUseDOM) {
    return;
  }

  if (deviceInfo.isSafari || deviceInfo.isEdge) {
    animateScrollTo(targetScroll - 200);
  } else {
    window.scrollTo({
      top: targetScroll - 200,
      behavior: 'smooth'
    });
  }
};
