import {
  SET_NAVIGATION,
  SET_HERO,
  SET_CONTENTS,
  SET_WORK,
  SET_CONTACT,
  SET_FOOTER_NAVIGATION,
  SET_FOOTER_LEGAL,
  SET_RECENT_NEWS
} from './actionTypes';

export default (state = {}, action) => {
  switch (action.type) {
    case SET_NAVIGATION:
      return { ...state, navigation: action.data };
    case SET_HERO:
      return { ...state, hero: action.data };
    case SET_CONTENTS:
      return { ...state, contents: action.data };
    case SET_WORK:
      return { ...state, work: action.data };
    case SET_CONTACT:
      return { ...state, contact: action.data };
    case SET_FOOTER_NAVIGATION:
      return { ...state, footerNavigation: action.data };
    case SET_FOOTER_LEGAL:
      return { ...state, footerLegal: action.data };
    case SET_RECENT_NEWS:
      return { ...state, recentNews: action.data };
    default:
      return { ...state };
  }
};
