import { breakpoints } from 'config/constants';

import { cloneDeep } from 'helpers/utilities';
import {
  compose,
  toNumber,
  isDefined,
  isString,
  appendPX,
  prop,
  add,
  sub,
  mul,
  div,
  identity,
  map,
  flip,
  keys,
  flipProp,
  flipIndexOf,
  flipTake,
  removeWhiteSpace
} from './fp';

const arithmeticOperations = {
  '*': mul,
  '+': add,
  '/': flip(div),
  '-': flip(sub)
};

const oppositeOperations = {
  '*': '/',
  '/': '*',
  '+': '-',
  '-': '+'
};

const orderOfOperation = [
  ['*', '/'],
  ['+', '-']
];

const solveArithmetic = arithmetic => {
  const [number1, operation, number2] = arithmetic.split(/(\*|\+|-|\/)/);
  return arithmeticOperations[operation](toNumber(number1))(toNumber(number2));
};

const mathEval = expression => {
  // eslint-disable-next-line no-restricted-syntax
  for (const operation of orderOfOperation) {
    // eslint-disable-next-line prefer-template
    const newOperation = operation.map(o => `${'\\' + o}`).join('|');
    const regex = new RegExp(`[0-9]+(\\.[0-9]+)*(${newOperation})[0-9]+(\\.[0-9]+)?`, 'g');
    if (expression.match(regex)) {
      expression = expression.replace(regex, solveArithmetic);
      return mathEval(expression);
    }
  }
  return toNumber(expression);
};

const updateWidth = (width, x) => layout => ({
  ...layout,
  width,
  x1: layout.x1 + (width - layout.width) * ((1 + (x - 1)) * -1),
  x2: layout.x2 + (width - layout.width) * (1 - x)
});

const updateHeight = (height, y) => layout => ({
  ...layout,
  height,
  y1: layout.y1 + (height - layout.height) * ((1 + (y - 1)) * -1),
  y2: layout.y2 + (height - layout.height) * (1 - y)
});

const updateSize = ({ width, height, x, y }) =>
  compose(updateHeight(height, y), updateWidth(width, x));

const updateAxis = axis => (a, b) => layout => {
  const newAxis = {};
  if (isDefined(a)) {
    newAxis[axis + 1] = a;
    newAxis[axis + 2] = a + (layout[axis + 2] - layout[axis + 1]);
  } else if (isDefined(b)) {
    newAxis[axis + 2] = b;
    newAxis[axis + 1] = b - (layout[axis + 2] - layout[axis + 1]);
  }
  return { ...layout, ...newAxis };
};

const updateXAxis = updateAxis('x');
const updateYAxis = updateAxis('y');

const updatePosition = ({ x1, x2, y1, y2 }) => compose(updateXAxis(x1, x2), updateYAxis(y1, y2));

const updateLayout = ({ width, height, x1, y1, x2, y2, x, y }) =>
  compose(updatePosition({ x1, y1, x2, y2 }), updateSize({ width, height, x, y }));

const createLayout = ({ width, height, x1, y1, x2, y2, x = 0.5, y = 0.5 }) => {
  if (isDefined(x1)) {
    x2 = x1 + width;
  } else if (isDefined(x2)) {
    x1 = x2 - width;
  }

  if (isDefined(y1)) {
    y2 = y1 + height;
  } else if (isDefined(y2)) {
    y1 = y2 - height;
  }

  return { width, height, x1, y1, x2, y2, x, y };
};

const evaluateExpression = (expression, constraints) => {
  const operation = expression.slice(0, -(expression.length - 1));
  const subExpression = expression.slice(1);
  const arithmeticExpression = subExpression.replace(/\$.*\$/g, variable => {
    const [relative, property] = variable.slice(1, -1).split('.');
    return constraints[relative].layout[property];
  });
  const number = mathEval(arithmeticExpression);
  const oppositeOperation = oppositeOperations[operation];
  const arithmeticOperation = arithmeticOperations[operation];

  if (arithmeticOperation) {
    return [flip(arithmeticOperation)(number), oppositeOperation + subExpression];
  }
  return [identity, ''];
};

const evaluateLayoutPoint = (index, constraint, constraints) => (name, value) => {
  if (isString(value)) {
    const [relative, property, expression = ''] = value.split(',');
    const relativeValue = constraints[relative].layout[property];

    const [getExpressionValue, oppositeExpression] = evaluateExpression(expression, constraints);

    // hmm mutation here?, let's edit the internal api here if still have time
    constraint.relatives[relative] = constraint.relatives[relative] || {};
    constraint.relatives[relative][name] = value;

    constraints[relative].relatives[index] = constraints[relative].relatives[index] || {};
    constraints[relative].relatives[index][property] = [index, name, oppositeExpression].join(',');

    return getExpressionValue(relativeValue);
  }
  return value;
};

export const createRelativeConstraints = layouts => {
  const constraints = [];
  layouts.forEach(({ width, height, x1, x2, y1, y2, x, y }, index) => {
    const constraint = { layout: {}, relatives: {} };
    const evaluateCurrentLayoutPoint = evaluateLayoutPoint(index, constraint, constraints);
    x1 = evaluateCurrentLayoutPoint('x1', x1);
    x2 = evaluateCurrentLayoutPoint('x2', x2);
    y1 = evaluateCurrentLayoutPoint('y1', y1);
    y2 = evaluateCurrentLayoutPoint('y2', y2);

    constraint.layout = createLayout({
      width,
      height,
      x1,
      y1,
      x2,
      y2,
      x,
      y
    });

    constraints.push(constraint);
  });
  return constraints;
};

const getRelativeConstraint = (relatives, constraints) => {
  const relativeConstraints = {};

  Object.keys(relatives).forEach(key => {
    const relativeRelatives = constraints[key].relatives;
    const relativeLayout = constraints[key].layout;

    Object.keys(relativeRelatives).forEach(relativeKey => {
      const relationships = relativeRelatives[relativeKey];

      Object.keys(relationships).forEach(relationshipKey => {
        const relationship = relationships[relationshipKey];
        const [relative, property, expression = ''] = relationship.split(',');
        const relativeValue = constraints[relative].layout[property];

        const [getExpressionValue] = evaluateExpression(expression, constraints);

        const relationshipValue = getExpressionValue(relativeValue);
        if (
          toNumber(parseFloat(relationshipValue).toFixed(2)) !==
          toNumber(parseFloat(relativeLayout[relationshipKey]).toFixed(2))
        ) {
          relativeConstraints[key] = relativeConstraints[key] || {};
          relativeConstraints[key][relationshipKey] = relationshipValue;
        }
      });
    });
  });

  return relativeConstraints;
};

const recursiveUpdate = (index, layout, constraints) => {
  const constraint = constraints[index];

  const currentLayout = constraint.layout;
  const currentRelatives = constraint.relatives;

  const newLayout = updateLayout({
    width: currentLayout.width,
    height: currentLayout.height,
    x: currentLayout.x,
    y: currentLayout.y,
    ...layout
  })(currentLayout);

  constraint.layout = newLayout;

  const relativeConstraints = getRelativeConstraint(currentRelatives, constraints);

  Object.keys(relativeConstraints).map(key =>
    recursiveUpdate(key, relativeConstraints[key], constraints)
  );

  return constraints;
};

export const updateRelativeConstraint = (index, layout) => constraints =>
  recursiveUpdate(index, layout, cloneDeep(constraints));

export const getLayouts = map(prop('layout'));

export const parseStyle = ({ width, height, x1, y1 }) => ({
  width: appendPX(width),
  height: appendPX(height),
  top: appendPX(y1),
  left: appendPX(x1)
});

export const parseStylePercentage = (size, { width, height, x1, y1 }) => ({
  width: `${(width / size.width) * 100}%`,
  height: `${(height / size.height) * 100}%`,
  top: `${(y1 / size.height) * 100}%`,
  left: `${(x1 / size.width) * 100}%`
});

export const resizeDimension = (dimension, width) => ({
  width,
  height: width * (dimension.height / dimension.width)
});
export const createViewWidth = ({ width, height }, innerWidth) => ({
  width: `${(width / innerWidth) * 100}vw`,
  height: `${(height / innerWidth) * 100}vw`
});

export const createMaxDimension = ({ width, height }, maxWidth) => ({
  maxWidth: `${maxWidth}px`,
  maxHeight: `${(maxWidth * height) / width}px`
});

export const matchMedias = (_breakpoints, callback) => {
  const medias = _breakpoints.map(breakpoint => window.matchMedia(breakpoint));
  const listener = e => callback(e);
  medias.map(media => media.addListener(listener));
  medias.map(media => callback(media));
  return () => medias.map(media => media.removeListener(listener));
};

export const matchBreakpoints = callback => {
  const inBetweenBreakPoints = {
    mobile: `only screen and (max-width: ${breakpoints.tablet - 1}px) and (min-width: ${
      breakpoints.mobile
    }px)`,
    tablet: `only screen and (max-width: ${breakpoints.smallDesktop - 1}px) and (min-width: ${
      breakpoints.tablet
    }px)`,
    smallDesktop: `only screen and (max-width: ${breakpoints.largeDesktop - 1}px) and (min-width: ${
      breakpoints.smallDesktop
    }px)`,
    largeDesktop: `only screen and (min-width: ${breakpoints.largeDesktop}px)`
  };
  const extractMedias = compose(map(flipProp(inBetweenBreakPoints)), keys);
  const medias = extractMedias(inBetweenBreakPoints);
  const mediasWithNoWhiteSpace = map(removeWhiteSpace)(medias);
  const getMediaKey = compose(
    flipTake(keys(inBetweenBreakPoints)),
    flipIndexOf(mediasWithNoWhiteSpace),
    removeWhiteSpace
  );
  const unsubscribe = matchMedias(medias, e => {
    if (e.matches) {
      callback(getMediaKey(e.media));
    }
  });
  return unsubscribe;
};

// should we add engine for centralized request animation frame?
export const getThrottledRAF = () => {
  let ticking = false;
  return callback => {
    if (!ticking) {
      window.requestAnimationFrame(time => {
        callback(time);
        ticking = false;
      });
    }
    ticking = true;
  };
};

export const safeRender = (condition, Component) => (condition ? Component : null);
