import PubSub from 'pubsub-js';
import { EVENT } from '../config/constants';
import canUseDOM from './canUseDOM';

class EventHandler {
  constructor() {
    if (!canUseDOM) {
      return;
    }

    this.body = document.body;
    this.scrollObject = {};
    this.resizeObject = {};
    this.lastScrollTop = 0;
    this.bodyOffset = 0;

    // Handle Resize Event
    window.addEventListener('resize', e => {
      this.resizeObject = {
        time: e.timeStamp,
        width: window.innerWidth,
        height: window.innerHeight,
        outerWidth: window.outerWidth,
        outerHeight: window.outerHeight
      };

      PubSub.publish(EVENT.RESIZE, this.resizeObject);
    });

    window.addEventListener('keydown', e => {
      PubSub.publish(EVENT.KEYDOWN, e);
      document.body.classList.remove('using-mouse');
    });

    window.addEventListener('mousedown', () => {
      document.body.classList.add('using-mouse');
    });
  }

  subscribe(event, info) {
    if (event === EVENT.SCROLL) {
      // Listen only on scroll once subscribed.
      this.listenToScroll();
    }

    return PubSub.subscribe(event, info);
  }

  unsubscribe(token) {
    return PubSub.unsubscribe(token);
  }

  listenToScroll() {
    if (this.scrollHandled) {
      return;
    }

    this.scrollHandled = true;

    window.addEventListener(
      'scroll',
      e => {
        this.bodyOffset = this.body.offsetTop - (window.scrollY || window.pageYOffset);
        if (this.bodyOffset === 0) {
          return;
        }
        this.scrollObject = {
          time: e.timeStamp,
          scrollY: -this.bodyOffset,
          direction: this.lastScrollTop > -this.bodyOffset ? 'down' : 'up'
        };

        this.lastScrollTop = -this.bodyOffset;

        PubSub.publish(EVENT.SCROLL, this.scrollObject);
      },
      {
        capture: false,
        passive: true
      }
    );
  }
}

export default new EventHandler();
