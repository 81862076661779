import React from 'react';
import { connect } from 'react-redux';

import CATHSection from '../CATHSection';

import styles from './styles.scss';

const HealthIntro = ({ hero }) => {
  if (!hero) {
    return null;
  }

  const { healthHeading, healthBody, healthBackgroundImage } = hero;

  const [{ url: imageUrl }] =
    Array.isArray(healthBackgroundImage) && healthBackgroundImage.length
      ? healthBackgroundImage
      : [{}];
  const { content } = healthBody;

  return (
    <CATHSection
      className={styles.intro}
      bgImage={imageUrl}
      bgClassName={styles.background}
      bgAlt="Abstract background image">
      <h2 className={styles.heading}>
        <span>{healthHeading}</span>
      </h2>
      <div className={styles.paragraph} dangerouslySetInnerHTML={{ __html: content }} />
    </CATHSection>
  );
};

const mapStateToProps = storeState => ({
  hero: storeState.hero
});

export default connect(mapStateToProps)(HealthIntro);
