import React from 'react';

import styles from './styles.scss';

const Heading = ({ text, noline = false, classes = '' }) => (
  <h2 className={`${styles.heading} ${noline ? styles.noline : ''} ${classes}`}>
    <span>{text}</span>
    <span className={styles.dot}>.</span>
  </h2>
);

export default Heading;
