import React, { Fragment, useRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import cn from 'classnames';

import Image from '../Image';
import GridOutline from '../HealthGridOutline';
import HealthHeaderLogo from '../HealthHeaderLogo';
import { scrollTo } from '../../../helpers/ScrollTop';
import useBreakpoint from '../../../hooks/useBreakpoint';
import { gtmPush } from '../../../helpers/dataLayer';

import styles from './styles.scss';

function linkClickHandler({ currentTarget }) {
  let hash = currentTarget.href.match(/#.+$/);
  hash = hash ? hash[0] : '';
  const matchedSection = document.querySelector(hash);

  if (matchedSection) {
    const targetScroll = matchedSection.getBoundingClientRect().top + window.pageYOffset;

    // Adding a tab index so the section can be focused.
    matchedSection.setAttribute('tabindex', '-1');
    matchedSection.focus();

    scrollTo(targetScroll);
  }
}

const Company = ({ label, subLabel, image, url }) => {
  const breakpoint = useBreakpoint();
  const isMobileTablet = breakpoint === 'mobile' || breakpoint === 'tablet';
  const [subLabelVisibility, setSubLabelVisibility] = useState(false);

  return (
    <Fragment key={url}>
      <div
        className={`${styles.company} ${styles.headerContent}`}
        onClick={({ currentTarget }) => {
          currentTarget.blur();
        }}
        onMouseEnter={() => setSubLabelVisibility(true)}
        onMouseLeave={() => setSubLabelVisibility(false)}>
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.company__content}
          onClick={() => {
            gtmPush({ event: 'navigationItem' });
          }}
          dangerouslySetInnerHTML={{ __html: label }}
        />
        {subLabel && !isMobileTablet && (
          <CSSTransition
            in={subLabelVisibility}
            timeout={500}
            unmountOnExit={true}
            classNames={styles.company__subLabel}>
            <div dangerouslySetInnerHTML={{ __html: subLabel }} />
          </CSSTransition>
        )}
        {subLabel && isMobileTablet && <div dangerouslySetInnerHTML={{ __html: subLabel }} />}
      </div>
      <div className={styles.frostedPanel} data-url={url}>
        <div className={styles.frostedPanel__content}>
          {image && <Image image={image.url} sizes={[{ actual: 117, intrinsic: 300 }]} />}
        </div>
      </div>
    </Fragment>
  );
};

const Companies = ({ companies }) => (
  <>
    {companies.map(({ label, subLabel, navigationUrl, image }, index) => (
      <Company
        key={index}
        label={label.content}
        subLabel={subLabel && subLabel.content}
        url={navigationUrl}
        image={image[0]}
      />
    ))}
  </>
);

const HealthHeader = props => {
  const { navigation, contact } = props;

  const headerRef = useRef();

  // Populate linkItems
  const contactLink = contact && { text: 'Contact', url: '#contact' };

  // componentDidMount
  useEffect(() => {
    function handleScroll() {
      if (!headerRef.current) {
        return;
      }

      window.requestAnimationFrame(() => {
        headerRef.current.classList.toggle(styles['header--scrolled'], window.pageYOffset > 50);
      });
    }

    window.addEventListener('scroll', handleScroll);

    // Call the scroll handler manually on initial load to toggle header classes.
    handleScroll();

    // Cleanup
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header className={styles.header} ref={headerRef}>
      <GridOutline />

      <div className={styles.content}>
        <Link className={styles.logo} to="/">
          <HealthHeaderLogo />
        </Link>
        <div className={styles.headerContents}>
          {navigation && navigation.healthNavigationItems ? (
            <Companies companies={navigation.healthNavigationItems} />
          ) : null}
          {contactLink && (
            <div className={cn(styles.links, styles.headerContent)}>
              <Link
                to={contactLink.url}
                data-url={contactLink.url}
                className={styles.link}
                onClick={linkClickHandler}>
                <span className={styles.link__text}>{contactLink.text}</span>
              </Link>
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

const mapStateToProps = state => ({
  navigation: state.navigation,
  contact: state.contact
});

export default connect(mapStateToProps)(HealthHeader);
