import React from 'react';

import Section from '../CATHSection';
import InfoCard from '../InfoCard';

import styles from './styles.scss';

const HealthInfo = ({ bgImage = {}, items }) => (
  <Section
    className={styles.info}
    bgImage={bgImage.url}
    bgClassName={styles.bgImage}
    bgAlt={bgImage.title}
    bgSpeed={-0.1}>
    {items.map(({ title, body }, index) => (
      <InfoCard
        classes={styles.card}
        number={index + 1}
        headingText={title}
        body={body}
        key={index}
      />
    ))}
  </Section>
);

export default HealthInfo;
