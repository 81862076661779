import React from 'react';
import { connect } from 'react-redux';

import Section from '../CATHSection';
import ContactCard from '../ContactCard';
import LocationCard from '../LocationCard';
import Image from '../Image';
import HulkText from '../HulkText';

import styles from './styles.scss';

const HealthContact = ({ contact }) => {
  // Dont render if no contact is set
  if (!contact) {
    return null;
  }

  const { healthHeading, healthContactEmails, healthContactLocations, healthLocationMap } = contact;

  const contactList = healthContactEmails.map(({ departmentName, emailAddress, ctaLinkName }) => ({
    heading: departmentName,
    ctaLinkName,
    link: emailAddress,
    type: 'email'
  }));

  const locationList = healthContactLocations.map(
    ({ abbreviation, cityAndState, officeName, officeAddress, contactNumber }) => ({
      heading: abbreviation,
      division: officeName,
      subheading: cityAndState.content,
      address: officeAddress.content,
      phone: contactNumber
    })
  );

  const locationMapImage =
    healthLocationMap && healthLocationMap.length ? healthLocationMap[0] : null;

  return (
    <Section className={styles.contact} data-name="contact">
      <HulkText classes={styles.hulkText} text={healthHeading} htmlProps={{ id: 'contact' }} />
      <div className={styles.contactCards}>
        {contactList.map((contact, index) => (
          <ContactCard
            classes={styles.contactCard}
            headingClasses={styles.contactCardHeading}
            linkClasses={styles.contactCardLink}
            key={index}
            {...contact}
          />
        ))}
      </div>
      {locationList.length ? (
        <div className={styles.locationCards}>
          {locationList.map((location, index) => (
            <LocationCard
              classes={styles.locationCard}
              headingClasses={styles.locationCardHeading}
              linkClasses={styles.locationCardLink}
              key={index}
              {...location}
            />
          ))}
        </div>
      ) : null}
      {locationMapImage && (
        <div className={styles.locationMap}>
          <Image
            ariaHidden
            image={locationMapImage.url}
            alt={locationMapImage.title}
            imgixParams={{ q: 100 }}
            sizes={[
              { actual: 375, intrinsic: 450 },
              { actual: 768, intrinsic: 1024 },
              { actual: 1088, intrinsic: 1280 }
            ]}
          />
        </div>
      )}
    </Section>
  );
};

const mapStateToProps = storeState => ({
  contact: storeState.contact
});

export default connect(mapStateToProps)(HealthContact);
