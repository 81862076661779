import React from 'react';
import styles from './styles.scss';

const HulkText = ({ text, classes, htmlProps = {} }) => (
  <h2 className={`${styles.hulk_text} ${classes}`} {...htmlProps}>
    <span>{text}</span>
  </h2>
);

export default HulkText;
