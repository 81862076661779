import React from 'react';
import { Helmet } from 'react-helmet-async';
import { cleanURL } from '../../helpers/text';

const Meta = ({
  active,
  title = '',
  description = '',
  imageURL = '',
  keywords = '',
  suffix = 'HEALTH'
}) => {
  const meta = {
    title: title ? `${title} - ${suffix}` : suffix,
    description:
      description ||
      'Code and Theory is a creative digital agency that designs products, content and campaigns across platforms.',
    imageURL: imageURL || `${window.location.origin}/images/candt-logo.jpg`,
    keywords
  };
  return active ? (
    <Helmet>
      <html lang="en" />
      <title>{meta.title}</title>
      <meta name="description" content={meta.description} />
      <meta name="keywords" content={meta.keywords || ''} />

      {/* Opengraph Tags */}
      <meta property="og:title" content={meta.title} />
      <meta property="og:description" content={meta.description} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={cleanURL(window.location.href)} />
      <meta
        property="og:image"
        content={`${cleanURL(meta.imageURL)}?w=1200&auto=compress,format`}
      />
      <meta property="og:image:alt" content={cleanURL(meta.description)} />

      {/* Twitter card tags */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:site" content="@codeandtheory" />
      <meta property="twitter:title" content={meta.title} />
      <meta property="twitter:description" content={meta.description} />
      <meta
        property="twitter:image"
        content={`${cleanURL(meta.imageURL)}?w=1200&auto=compress,format`}
      />
      <meta property="twitter:image:alt" content={cleanURL(meta.description)} />
    </Helmet>
  ) : null;
};

export default Meta;
