import { keys as _keys } from './utilities';

export const compose =
  (...fns) =>
  data =>
    fns.reduceRight((value, acc) => acc(value), data);
export const map = f => fn => fn.map(f);
export const flip = fn => a => b => fn(b)(a);
export const run = fn => data => {
  fn(data);
  return data;
};

export const toString = data => String(data);
export const toNumber = data => Number(data);

export const isDefined = data => data !== null && data !== undefined;
export const isString = data => typeof data === 'string';

export const concat = str => data => data.concat(str);
export const prepend = flip(concat);
export const appendPX = compose(concat('px'), toString);
export const appendPercentage = compose(concat('%'), toString);

export const prop = property => object => object[property];
export const flipProp = flip(prop);

export const add = a => b => a + b;
export const sub = a => b => b - a;
export const div = a => b => b / a;
export const mul = a => b => a * b;

// combinators
export const identity = a => a;
export const always = a => () => a;

export const swap = (indexA, indexB, array) => {
  const list = [...array];
  const tmp = list[indexA];
  list[indexA] = list[indexB];
  list[indexB] = tmp;
  return list;
};

// string
export const split = str => data => data.split(str);
export const join = str => data => data.join(str);
export const match = reg => str => str.match(reg);
export const replace = (reg, m) => str => str.replace(reg, m);
export const indexOf = str => data => data.indexOf(str);
export const flipIndexOf = flip(indexOf);
export const slice = (start, end) => str => str.slice(start, end);

// array
export const tail = arr => arr[arr.length - 1];
export const take = index => array => array[index];
export const last = array => array[array.length - 1];
export const flipTake = flip(take);
export const createArray = length => Array(length).fill();
export const unique = array => [...new Set(array)];
// cond
export const ifElse = (p, fn1, fn2) => v => p(v) ? fn1(v) : fn2(v);
export const cond =
  (...conds) =>
  v => {
    let retVal;
    // eslint-disable-next-line no-restricted-syntax
    for (const c of conds) {
      if (c[0](v)) {
        retVal = c[1](v);
        break;
      }
    }
    return retVal;
  };

const nthKeys = {
  top: () => 0,
  bottom: count => count - 1
};

export const nthKeyToIndex = (key, length) => (nthKeys[key] ? nthKeys[key](length) : null);

// object
export const keys = obj => _keys(obj);
export const merge = (...objects) => objects.reduce((a, b) => ({ ...a, ...b }), {});

// util
export const gte = n => v => v >= n;
export const lte = n => v => v <= n;
export const clamp = (min, max) =>
  cond([lte(min), always(min)], [gte(max), always(max)], [always(true), identity]);
export const removeWhiteSpace = replace(/ /g, '');
export const shift = (a, b) => c => a === c ? b : a;

// function
export const noop = () => {};
