import React from 'react';
import { Switch, Route } from 'react-router-dom';
import lazysizes from 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';
import 'lazysizes/plugins/optimumx/ls.optimumx';

import { HelmetProvider } from 'react-helmet-async';
import HealthProvider from './components/HealthComps/HealthProvider';
import { BreakpointProvider } from './components/Breakpoint';
import Health from './containers/Health';
import ErrorHandler from './components/ErrorHandler';
import './styles/global.scss';

// https://github.com/aFarkas/lazysizes#js-api
// custom config of lazysizes for lazyloading
// expand - viewport expansion
// expFactor - factor to download images if in idle mode
lazysizes.cfg.loadMode = 1;
lazysizes.cfg.expand = 1000;
lazysizes.cfg.expFactor = 3;
const helmetContext = {};

const App = () => (
  <HelmetProvider context={helmetContext}>
    <BreakpointProvider>
      <HealthProvider>
        <ErrorHandler>
          <Switch>
            <Route path="/" exact component={Health} />
            <Route
              path="*"
              render={() => {
                throw new Error('No Entry');
              }}
            />
          </Switch>
        </ErrorHandler>
      </HealthProvider>
    </BreakpointProvider>
  </HelmetProvider>
);

export default App;
