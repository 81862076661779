import React, { useEffect, useState, useRef, useCallback } from 'react';

import styles from './styles.scss';

const RightArrow = ({ goToNextSlide, disabled }) => (
  <button
    type="button"
    className={styles.nextArrow}
    onClick={goToNextSlide}
    disabled={disabled}
    aria-label="Go to Next Slide"
    aria-disabled={disabled}>
    Next
  </button>
);

const LeftArrow = ({ goToPrevSlide, disabled }) => (
  <button
    type="button"
    className={styles.backArrow}
    onClick={goToPrevSlide}
    disabled={disabled}
    aria-label="Go to Previous Slide"
    aria-disabled={disabled}>
    Previous
  </button>
);

const isBpTablet = () => window.matchMedia('(min-width: 768px) and (max-width: 959px)').matches;
const isBpMobile = () => window.matchMedia('(max-width: 767px)').matches;
const winWidth = () =>
  window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

const Carousel = props => {
  const { slides, fullscreen, modal } = props;
  const slidesTotal = slides ? slides.length : 0;

  if (slidesTotal === 0) return <div className={styles.carousel}>Nothing to display</div>;

  const [windowWidth, setWindowWidth] = useState(0);
  const [activeSlide, setActiveSlide] = useState(1);
  const [offset, setOffset] = useState(0);
  const [isTablet, setIsTablet] = useState(isBpTablet());
  const [isMobile, setIsMobile] = useState(isBpMobile());

  const currentSlideIndex = useRef(activeSlide);

  const goToPrevSlide = useCallback(() => {
    setActiveSlide(currentSlideIndex.current - 1);
  }, []);

  const goToNextSlide = useCallback(() => {
    setActiveSlide(currentSlideIndex.current + 1);
  }, []);

  // Component did mount
  useEffect(() => {
    function updateWindowWidth() {
      setIsTablet(isBpTablet());
      setIsMobile(isBpMobile());
    }

    updateWindowWidth();
    window.addEventListener('resize', updateWindowWidth);

    // Cleanup
    return () => {
      updateWindowWidth();
      window.removeEventListener('resize', updateWindowWidth);
    };
  }, []);

  useEffect(() => {
    setActiveSlide(1);
    setOffset(0);

    if (fullscreen) {
      setWindowWidth(winWidth() - 40);
    } else {
      if (isTablet) {
        setWindowWidth({ windowWidth: 266 + 25 });
      }
      if (isMobile) {
        setWindowWidth({ windowWidth: 250 + 10 });
      }
    }
  }, [isTablet, isMobile]);

  useEffect(() => {
    if (activeSlide < currentSlideIndex.current) {
      const targetOffset = activeSlide === 1 ? 0 : offset + windowWidth;
      setOffset(targetOffset > 0 ? 0 : targetOffset);
    } else {
      setOffset(-(windowWidth * (activeSlide - 1)));
    }

    currentSlideIndex.current = activeSlide;
  }, [activeSlide]);

  return (
    <div
      className={`${styles.carousel} ${fullscreen ? styles['carousel--fullscreen'] : ''} ${
        modal ? styles['carousel--modal'] : ''
      }`}>
      <div className={styles.carousel__wrapper}>
        <div className={styles.carousel__counter}>
          <span className={styles.current}>{activeSlide}</span> / <span>{slidesTotal}</span>
        </div>
        <div className={styles.carousel__pagination}>
          <LeftArrow goToPrevSlide={goToPrevSlide} disabled={activeSlide === 1} />
          <RightArrow goToNextSlide={goToNextSlide} disabled={activeSlide === slidesTotal} />
        </div>
        <div className={styles.carousel__slides}>
          {slides !== undefined && (
            <ul
              className={styles.slides}
              style={{
                width: windowWidth * slidesTotal,
                transform: `translateX(${offset}px)`
              }}>
              {slides.map(slide => (
                <li
                  key={slide.id}
                  className={styles.slide__item}
                  aria-hidden={activeSlide !== slide.id}>
                  {slide.content}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default Carousel;
