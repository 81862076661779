import { gql } from 'apollo-boost';

export default gql`
  {
    entry(slug: "home", site: "health") {
      title
      url
      ... on Health {
        healthFields {
          __typename
          ... on HealthFieldsHealthNavigationBlock {
            healthNavigationItems {
              label {
                content
              }
              subLabel {
                content
              }
              navigationUrl
              image {
                url
              }
            }
          }
          ... on HealthFieldsHealthContentBlock {
            healthAlignment
            healthBigText
            healthHeading
            healthBody {
              content
            }
            healthCta {
              label
              ctaLink
            }
            healthInfoCards {
              infoCardTitle
              infoCardDescription {
                content
              }
            }
            healthBackgroundImage {
              url
              title
            }
          }
          ... on HealthFieldsHealthWorkBlock {
            healthBigText
            healthHeading
            healthBody {
              content
            }
            healthProjects {
              id
              projectName
              description
              useExternalLink
              projectLink
              externalLink
              imageGallery {
                url
                title
              }
            }
          }
          ... on HealthFieldsHealthContact {
            healthHeading
            healthContactEmails {
              departmentName
              ctaLinkName
              emailAddress
            }
            healthContactLocations {
              abbreviation
              cityAndState {
                content
              }
              officeName
              officeAddress {
                content
              }
              contactNumber
            }
            healthLocationMap {
              title
              url
            }
          }
          ... on HealthFieldsHealthFooterNavigationBlock {
            healthFooterNavigationItems {
              label {
                content
              }
              navigationUrl
            }
          }
          ... on HealthFieldsHealthFooterLegalBlock {
            healthCopyrightText
            healthSocialMediaLinks {
              socialMediaType
              label
              socialMediaUrl
            }
          }
        }
        healthRecentNews {
          eyebrow
          image {
            url
            title
          }
          description
          linkType
          internalLink {
            url
            uri
            title
          }
          externalLink
        }
      }
    }
  }
`;
