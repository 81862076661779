import React from 'react';

import styles from './styles.scss';

const BackgroundImage = () => (
  <div className={styles.grid}>
    <div className={styles.grid__child} />
  </div>
);

export default BackgroundImage;
