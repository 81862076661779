import React from 'react';
import { connect } from 'react-redux';

import Icon from '../../Icon';
import styles from './styles.scss';

const socialIcons = {
  linkedin: <Icon name="linkedin" classes={styles.socialIcon} />,
  twitter: <Icon name="twitter" classes={styles.socialIcon} />,
  instagram: <Icon name="instagram" classes={styles.socialIcon} />
};

const getIcon = socialType => {
  const iconType = socialType.toLowerCase();
  return iconType in socialIcons ? socialIcons[iconType] : null;
};

const SocialItem = ({ social }) => {
  if (/linkedin|twitter|instagram/i.test(social.type)) {
    return (
      <a
        className={styles.link}
        href={social.url}
        target="_blank"
        rel="noopener noreferrer"
        title={`Visit Code & Theory on ${social.text}`}>
        {getIcon(social.type) || social.text}
      </a>
    );
  }

  return (
    <a
      className={styles.link}
      href={social.url}
      dangerouslySetInnerHTML={{ __html: social.text }}
      target="_blank"
      rel="noopener noreferrer"
    />
  );
};

const HealthFooter = ({ footerNavigation, footerLegal }) => {
  const infoLinks = !footerNavigation
    ? []
    : footerNavigation.healthFooterNavigationItems.map(({ label, navigationUrl }) => ({
        text: label.content,
        url: navigationUrl
      }));

  const { healthCopyrightText, healthSocialMediaLinks = [] } = footerLegal || {};

  const socials = healthSocialMediaLinks.map(({ socialMediaType, label, socialMediaUrl }) => ({
    type: socialMediaType,
    text: label,
    url: socialMediaUrl
  }));

  return (
    <footer className={styles.footer}>
      <div className={styles.footerContent}>
        <ul className={styles.infoLinks}>
          {infoLinks.map(({ text, url }, index) => (
            <li key={index} className={styles.linkItem}>
              <a className={styles.link} href={url} dangerouslySetInnerHTML={{ __html: text }} />
            </li>
          ))}
        </ul>
        <div className={styles.footerBottom}>
          <ul className={styles.socials}>
            <li className={styles.socialItem}>
              <span className={styles.copyright}>{healthCopyrightText}</span>
            </li>
            {socials.map((socialItem, index) => (
              <li className={styles.socialItem} key={index}>
                <SocialItem social={socialItem} />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </footer>
  );
};

const mapStateToProps = storeState => ({
  footerNavigation: storeState.footerNavigation,
  footerLegal: storeState.footerLegal
});

export default connect(mapStateToProps)(HealthFooter);
