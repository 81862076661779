import React, { createContext, useState, useEffect, useRef } from 'react';
import { matchBreakpoints } from 'helpers/layout';

const Breakpoint = createContext('largeDesktop');

export const BreakpointProvider = ({ children }) => {
  const [breakpoint, setBreakpoint] = useState('largeDesktop');
  const breakpointRef = useRef('largeDesktop');

  useEffect(
    () =>
      matchBreakpoints(newBreakpoint => {
        if (breakpointRef.current !== newBreakpoint) {
          setBreakpoint(newBreakpoint);
          breakpointRef.current = newBreakpoint;
        }
      }),
    []
  );

  return <Breakpoint.Provider value={breakpoint}>{children}</Breakpoint.Provider>;
};

export default Breakpoint;
