import { upperFirst } from './utilities';
import { replace, compose, prepend, split, take } from './fp';
/**
 * @NOTE: All text helpers should be placed here.
 */

const text = null;

/**
 * Convert the * wrapped text to <strong> tag.
 * @param {string} str Text to be replaced.
 * @return {string} Will return the converted text.
 */
export const stringToBold = (str = '') => {
  const regex = /\*\s*([^*]*?)\s*\*/g;
  const matches = str.match(regex);
  let newStr = str;

  if (matches !== null && matches.length > 0) {
    for (let i = 0; i < matches.length; i++) {
      newStr = newStr.replace(matches[i], `<strong>${matches[i].replace(/\*/gi, '')}</strong>`);
    }
  }

  return newStr;
};

/**
 * Capitalize the first letter of the text.
 * @param {string} str Text to be capitalized.
 * @return {string} Will return the converted text.
 */
export const capitalize = str => str.replace(/^\w/, c => c.toUpperCase());

/**
 * Truncate a string after a certain number of characters
 * @param {string} str Text to be truncated
 * @param {integer} count Number of characters to return
 * @param {boolean} hasDots Whether to append an ellipsis to the truncated string or not
 * @return {string} Will return the truncated string with or without an ellipsis
 */
export const truncate = (str = '', count, hasDots = true) =>
  str.slice(0, hasDots ? count - 3 : count) + (str.length > count && hasDots ? '...' : '');

/**
 * Truncate a string after a certain number of characters with conserved word
 * @param {string} str Text to be truncated
 * @param {integer} count Number of characters to return
 * @param {boolean} suffix Whether to append an ellipsis to the truncated string or not
 * @return {string} Will return the truncated string with or without an ellipsis
 */
export const truncateByWord = (str = '', count, suffix = '...') => {
  if (str.length > count) {
    str = str.substring(0, count + 1);
    str = str.substring(0, Math.min(str.length, str.lastIndexOf(' ')));
    str += suffix;
  }
  return str;
};

/**
 * Wraps a string around each word
 * @param {string} str The string to transform
 * @param {string} tmpl Template that gets interpolated
 * @param {integer} depth Nesting depth of the wrapping element
 * @returns {string} The given input splitted by words
 */
export const wrapWords = (str, tmpl = 'span', depth = 1) =>
  str.replace(/\S+/g, `${`<${tmpl}>`.repeat(depth)}$&${`</${tmpl}>`.repeat(depth)}`);

export const stripParagraphTag = (str = '') => str.replace(/<\/?p[^>]*>/g, '');

export const convertAnchorLinks = str =>
  str.replace(/<a[^>]*>/g, match =>
    match
      .replace('http://', '')
      .replace(/".*"|'.*'/, link =>
        link.replace(/"|'/g, '').split('/').slice(1).join('/').replace(/^/, '"/').concat('"')
      )
  );

export const removeInfoSlug = (str = '') =>
  str.replace(
    new RegExp(`<a href="${window.location.origin}/info/`, 'g'),
    `<a href="${window.location.origin}/`
  );

// https://stackoverflow.com/questions/20856197/remove-non-ascii-character-in-string
// eslint-disable-next-line no-control-regex
export const removeUnicode = replace(/[^\x00-\x7F]/g, '');

/**
 * Turn words into slug. Remove unecessary spaces and  characters.
 */
export const slugify = str =>
  str
    .split(' ')
    .reduce((a, b) => {
      const x = b.replace(/[!@#$%^&*()+=`~><'";:/]/g, '');
      if (x) a.push(x);
      return a;
    }, [])
    .join('-')
    .replace(/_/g, '-')
    .toLowerCase();

export const deslugify = (str = '') => str.split('-').map(upperFirst).join(' ');

export const replaceTagsToSpace = (str = '') => str.replace(/<[^>]*>?/gm, ' ');

export const filterInnerHTML = compose(removeUnicode, stripParagraphTag);

export const removeURLProtocol = (str = '') => str.replace(/^http(s)?:\/\//i, '');

export const getHostName = compose(take(0), split('/'), removeURLProtocol);

export const parseExternalLink = compose(prepend('//'), removeURLProtocol);

export const setInnerHTML = string => ({
  __html: string
});

export const isolateStrongPerWord = (str = '') =>
  str.replace(/<strong>(.*?)<\/strong>/g, match =>
    match
      .replace(/(<strong>|<\/strong>)/g, '')
      .split(' ')
      .map(word => (word ? `<strong>${word}&nbsp;</strong>` : ' '))
      .join(' ')
  );

export const cleanURL = (str = '') => encodeURI(str.replace(/([^:]\/)\/+/g, '$1'));

export default text;
