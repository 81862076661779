import React from 'react';
import { withRouter } from 'react-router-dom';
import NotFound from '../NotFound';
import ServerError from '../ServerError';

class ErrorHandler extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error) {
    this.setState({ error });
  }

  renderError(error) {
    if (error.message === 'No Entry') {
      return <NotFound />;
    }
    return <ServerError error={error.stack} />;
  }

  render() {
    const { error } = this.state;
    const { children } = this.props;
    return error ? this.renderError(error) : children;
  }
}

export default withRouter(ErrorHandler);
