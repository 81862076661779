const gtmId = 'GTM-THV95HB';
const gtmIdScouthc = 'UA-91795-3';
const apiPath = process.env.RAZZLE_API_PATH;
const apiV2Path = process.env.RAZZLE_API_V2_PATH;
const apiToken = process.env.RAZZLE_API_TOKEN;
const apiTokenV2 = process.env.RAZZLE_API_TOKEN_V2;
const apiPrivateToken = process.env.RAZZLE_PRIVATE_API_TOKEN;
const breakpoints = {
  mobile: 320,
  tablet: 768,
  smallDesktop: 960,
  largeDesktop: 1248,
  maxpoint: 1440
};
const pageGutters = {
  mobile: 20,
  tablet: 48,
  smallDesktop: 64,
  largeDesktop: 64
};
const mediaQuery = {
  mobile: 'all and (max-width: 767px)',
  tablet: '(min-width: 768px) and (max-width: 959px)',
  smallDesktop: '(min-width: 960px) and (max-width: 1247px)',
  largeDesktop: 'all and (min-width: 1248px)'
};
const EVENT = {
  SCROLL: 'EVENT/SCROLL',
  RESIZE: 'EVENT/RESIZE',
  KEYDOWN: 'EVENT/KEYDOWN'
};

const articleTypes = [
  'Event',
  'News',
  'Press',
  'AccountsAccount',
  'CaseStudiesCaseStudy',
  'ThoughtLeadership',
  'CuratedWorkCuration'
];

export {
  gtmId,
  gtmIdScouthc,
  apiPath,
  apiToken,
  apiPrivateToken,
  breakpoints,
  pageGutters,
  mediaQuery,
  EVENT,
  articleTypes,
  apiV2Path,
  apiTokenV2
};
